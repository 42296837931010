import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import  Home from './pages/Home';
import  About from './pages/About';
import  Services from './pages/Services';
import  Contact from './pages/Contact';
function App() {
  return (
     <div className="">
     
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/about-us" element={<About />} />
        </Routes>
		  <Routes>
          <Route path="/services" element={<Services />} />
        </Routes>
		  <Routes>
          <Route path="/contact-us" element={<Contact />} />
        </Routes>
      </BrowserRouter>
  
    
  </div>
  );
}

export default App;
