import React,{Component} from "react";
import { Link } from "react-router-dom";
import ProfileImg from '../img/profile-img.jpg';
class Home extends Component
{
    render(){
        return (
		<div>
            <i class="bi bi-list mobile-nav-toggle d-lg-none"></i>

  <header id="header" class="d-flex flex-column justify-content-center">

    <nav id="navbar" class="navbar nav-menu">
      <ul>
        <li><Link to={'/'} className='nav-link scrollto active'><i class="bx bx-home"></i> <span>Home</span></Link>
		</li>
        <li><Link to={'/about-us'} className='nav-link scrollto'><i class="bx bx-user"></i> <span>About</span></Link>
		</li>
        <li><Link to={'/services'} className='nav-link scrollto'><i class="bx bx-server"></i> <span>Services</span></Link>
		</li>
        <li><Link to={'/contact-us'} className='nav-link scrollto '><i class="bx bx-envelope"></i> <span>Contact Us</span></Link>
		</li>
		
		
      </ul>
    </nav>

  </header>
  <section id="hero" class="d-flex flex-column justify-content-center">
    <div class="container" data-aos="zoom-in" data-aos-delay="100">
      <h1>Brandon Johnson</h1>
      <p>I'm <span class="typed" data-typed-items="Designer, Developer, Freelancer, Photographer"></span></p>
      <div class="social-links">
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      </div>
    </div>
  </section>
  </div>
        );
    }
}
export default Home;