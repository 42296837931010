import React,{Component} from "react";
import { Link } from "react-router-dom";
import ProfileImg from '../img/profile-img.jpg';
class Contact extends Component
{
    render(){
        return (
		<div>
            <i className="bi bi-list mobile-nav-toggle d-lg-none"></i>

  <header id="header" className="d-flex flex-column justify-content-center">

    <nav id="navbar" className="navbar nav-menu">
      <ul>
        <li><Link to={'/'} classNameName='nav-link scrollto active'><i className="bx bx-home"></i> <span>Home</span></Link>
		</li>
        <li><Link to={'/about-us'} classNameName='nav-link scrollto'><i className="bx bx-user"></i> <span>About</span></Link>
		</li>
        <li><Link to={'/services'} classNameName='nav-link scrollto'><i className="bx bx-server"></i> <span>Services</span></Link>
		</li>
        <li><Link to={'/contact-us'} classNameName='nav-link scrollto '><i className="bx bx-envelope"></i> <span>Contact Us</span></Link>
		</li>
		
		
      </ul>
    </nav>

  </header>
   <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Contact</h2>
        </div>

        <div className="row mt-1">

          <div className="col-lg-4">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>A108 Adam Street, New York, NY 535022</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info@example.com</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+1 5589 55488 55s</p>
              </div>

            </div>

          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">

           

          </div>

        </div>

      </div>
    </section>
  </div>
        );
    }
}
export default Contact;